<template>
    <div class="box">
        <div class="title">合作详情</div>
        <div class="content">
            <div class="content-item">
                <p class="content-label">公司</p>
                <p class="content-list">{{ detailList.company }}</p>
            </div>
            <div class="content-item">
                <p class="content-label">期限</p>
                <p class="content-list">{{ detailList.term }}</p>
            </div>
            <div class="content-item">
                <p class="content-label">续期</p>
                <p class="renewal">
                    <span v-for="(item , index) in detailList.renewal" :key="index" >开启</span>
                </p>
            </div>
            <div class="content-item">
                <p class="content-label">收入</p>
                <div class="content-list">
                    <p v-for="(item , index) in detailList.income" :key="index">资源分成20%</p>
                </div>
            </div>
            <div class="content-item">
                <div class="content-label">
                    <p>联系</p>
                    <p>电话</p>
                </div>
                <div class="content-list contact-number">
                    <p>{{ detailList.contact  }}</p>
                    <p>{{ detailList.contactNumber }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                detailList: {
                    company: '福建米聆科技有限公司',
                    term: '2023/10/21 至 2024/10/21',
                    renewal: ['开启','解绑','申诉'],
                    income: ['资源分成20%','资源分成20%','资源分成20%','资源分成20%','资源分成20%','资源分成20%'],
                    contact: '小李',
                    contactNumber: '13267531275'
                },
                loading: true,
            }
        },
        created() {
            
        }
    }
</script>

<style lang="scss" scoped>

.box {
	width: 100%;
	height: 785px;
	background-color: #FFFFFF;
    border-radius: 10px;
    padding-top: 51px;
    .title{
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #30373D;
        line-height: 14px;
    }
    .content{
        padding: 0 318px 0 242px;
        margin-top: 69px;
        .content-item{
            display: flex;
            align-items: flex-start;
            margin-bottom: 25px;
            .content-label{
                width: 30px;
                font-size: 15px;
                font-weight: bold;
                color: #30373D;
                line-height: 18px;
                margin-right: 67px;
                >p:first-child{
                    margin-bottom: 30px;
                }
            }
            .content-list{
                flex: 1;
                text-align: center;
                font-weight: 500;
                font-size: 15px;
                color: #30373D;
                line-height: 18px;
                >p{
                    margin-bottom: 20px;
                }
                >p:last-child{
                    margin-bottom: 10px;
                }
            }
            .contact-number{
                >p:first-child{
                    margin-bottom: 30px;
                }
                >p:last-child{
                    margin-bottom: 0;
                }
            }
            .renewal{
                flex: 1;
                display: flex;
                align-content: center;
                justify-content: space-between;
                font-size: 13px;
                line-height: 15px;
                >span{
                        padding: 7px 22px;
                        background-color: #F7F8FB;
                        border-radius: 5px;
                    }
            }
        }
    }
}
</style>